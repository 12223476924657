module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-52273564-2"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fff","display":"minimal-ui","icon":"static/logo.png","name":"Remote Creative Work","short_name":"RCW","start_url":"/","theme_color":"#fbcd33","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d7ff0c3f995a9e7cea92b97deab964cf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"canonical":"https://remotecreativework.com","description":"Find and connect with creative people all over the world: Hire remote creatives! Remote Creative Work is the best place to find and list remote jobs for creatives, that aren't restricted by office commutes or a singular location. Browse thousands of remote work jobs available for all creative types right now.","htmlAttributes":{"prefix":"og: https://ogp.me/ns#"},"language":"en","openGraph":{"description":"Find and connect with creative people all over the world: Hire remote creatives! Remote Creative Work is the best place to find and list remote jobs for creatives, that aren't restricted by office commutes or a singular location. Browse thousands of remote work jobs available for all creative types right now.","images":[{"url":"https://remotecreativework.com/logo.png","width":800,"height":800,"alt":"Remote Creative Work"}],"locale":"en_IE","site_name":"Remote Creative Work","title":"Connect with the most talented creative professionals, or find jobs, in Design, Development, Art, Audio, Social, Writing and more.","type":"website","url":"https://remotecreativework.com"},"title":"Connect with the most talented creative professionals, or find jobs, in Design, Development, Art, Audio, Social, Writing and more.","titleTemplate":"Remote Creative Work | %s"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"2.10.0","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"fa4cf3f5-5c74-537d-89f9-d56633419bbe","name":"gatsby-remark-images","version":"3.11.1","pluginOptions":{"plugins":[],"quality":90,"linkImagesToOriginal":false,"maxWidth":650,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{"default":"/opt/build/repo/src/templates/page/default/index.jsx"},"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-images","options":{"quality":90,"linkImagesToOriginal":false}}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"quality":90,"linkImagesToOriginal":false,"maxWidth":650,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
