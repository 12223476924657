// Material UI Custom Palette: https://material-ui.com/customization/palette/
module.exports = {
  type: "light",
  primary: {
    contrastText: "#22252d",
    dark: "#387ddd",
    light: "#eb1585",
    main: "#fbcd33",
  },
  secondary: {
    contrastText: "#fff",
    dark: "#3c4357",
    light: "#cbd3da",
    main: "#474d60",
  },
  grey: {
    50: "#f7f7f7",
    100: "#eeeeee",
    500: "#ced0d4",
    600: "#cbd3da",
    700: "#929292",
    900: "#737c84",
  },
  background: {
    default: "#fff",
    paper: "#f7f7f7",
  },
  divider: "#a4a8b3",
  text: {
    primary: "#3c4357",
    secondary: "#474d60",
  },
  error: {
    main: "#f37b44",
  },
  success: {
    main: "#70c36e",
  },
};
