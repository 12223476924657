// Material UI Custom Typography: https://material-ui.com/customization/typography/

// TODO: add more native font fallbacks for other os (i.e. windows, android, etc)

const bodyFont =
  "'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif";
const headingFont =
  "'Sentinel SSm A', 'Sentinel SSm B', 'IBM Plex Serif', 'Times New Roman', Georgia, serif";

module.exports = {
  fontHeading: headingFont,
  fontBody: bodyFont,
  fontSize: 16,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  fontWeightExtraBold: 700,
  h1: {
    fontFamily: headingFont,
    fontSize: "4.25rem",
    fontWeight: 600,
    letterSpacing: -2,
    lineHeight: 1,
  },
  h2: {
    fontFamily: headingFont,
    fontSize: "2.625rem",
    fontWeight: 700,
    letterSpacing: -2,
    lineHeight: 1.25,
  },
  h3: {
    fontFamily: bodyFont,
    fontSize: "2rem",
    fontWeight: 700,
    letterSpacing: -2,
    lineHeight: 2,
  },
  h4: {
    fontFamily: bodyFont,
    fontSize: "1.4375rem",
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 2,
  },
  h5: {
    fontFamily: bodyFont,
    fontSize: "1.125rem",
    fontWeight: 500,
    letterSpacing: -1.23,
    lineHeight: 2,
  },
  h6: {
    fontFamily: bodyFont,
    fontSize: "1rem",
    fontWeight: 500,
    letterSpacing: -1.23,
    lineHeight: 2,
  },
  subtitle1: {
    fontFamily: bodyFont,
    fontSize: "1rem",
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  subtitle2: {
    fontFamily: bodyFont,
    fontWeightBold: 500,
    letterSpacing: 0,
  },
  body1: {
    fontFamily: bodyFont,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.618,
  },
  body2: {
    fontFamily: bodyFont,
    fontWeight: 400,
    letterSpacing: 0,
  },
  label: {
    fontFamily: bodyFont,
    fontWeight: 500,
    letterSpacing: 0,
  },
  button: {
    fontFamily: headingFont,
    fontSize: "1rem",
    fontWeight: 600,
    letterSpacing: 0,
  },
  caption: {
    fontFamily: bodyFont,
    letterSpacing: 0,
    lineHeight: 1,
  },
  overline: {
    fontFamily: bodyFont,
    letterSpacing: 0,
  },
};
